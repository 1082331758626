<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <template>
      <br />
      <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Nuevo movimiento' }]" />
      <v-toolbar color="primary" dark card style="border-radius: 4px 4px 0 0">
        <v-toolbar-title>Tomador de pedidos</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Información de contacto</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">Selección de productos</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3">Revisión del pedido</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 4" step="4">Calculo del despacho</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-5">
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    class="checkout-form"
                    dense
                    id="email"
                    placeholder="Ingrese su e-mail"
                    name="email"
                    type="email"
                    :rules="[
                      (v) => !!v || 'Campo email es requerido',
                      (v) => /.+@.+\..+/.test(v) || 'Email no es valido.',
                    ]"
                    outline
                    v-model="email"
                    @change="searchForEmail"
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="form.nombre"
                    :disabled="processingForm"
                    label="Nombre"
                    outline
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="form.apellidos"
                    :disabled="processingForm"
                    label="Apellidos"
                    outline
                    :rules="rules.apellidos"
                    :error="!!formErrors.apellidos"
                    :error-messages="formErrors.apellidos"
                    @keyup="
                      () => {
                        formErrors.apellidos = undefined;
                        delete formErrors.apellidos;
                      }
                    "
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="form.telefono"
                    :disabled="processingForm"
                    label="Telefono"
                    outline
                    :rules="rules.telefono"
                    :error="!!formErrors.telefono"
                    :error-messages="formErrors.telefono"
                    @keyup="
                      () => {
                        formErrors.telefono = undefined;
                        delete formErrors.telefono;
                      }
                    "
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="form.direccion"
                    :disabled="processingForm"
                    label="Dirección"
                    outline
                    :rules="rules.direccion"
                    :error="!!formErrors.direccion"
                    :error-messages="formErrors.direccion"
                    @keyup="
                      () => {
                        formErrors.direccion = undefined;
                        delete formErrors.direccion;
                      }
                    "
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="form.ubicacion"
                    :disabled="processingForm"
                    label="Casa, Villa, Población, Km (Opcional)"
                    outline
                    :error="!!formErrors.direccion"
                    :error-messages="formErrors.direccion"
                    @keyup="
                      () => {
                        formErrors.direccion = undefined;
                        delete formErrors.direccion;
                      }
                    "
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-autocomplete
                    v-model="form.ciudad"
                    label="Comuna"
                    :items="cities.filter((city) => city.estado == true)"
                    class="checkout-form"
                    outline
                    :rules="[(v) => !!v || 'Campo Comuna es requerido']"
                    item-value="id"
                    item-text="nombre"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12>
                  <v-col cols="12" md="6">
                    <v-textarea
                      outline
                      v-model="form.notas"
                      name="input-7-4"
                      label="Comentarios o indicaciones para el despacho"
                    ></v-textarea>
                  </v-col>
                </v-flex>
              </v-layout>
            </v-card>
            <v-layout row wrap justify-center style="padding-bottom: 25px">
              <v-btn
                color="primary"
                large
                @click="e1 = 2"
                :disabled="
                  email == '' ||
                  form.nombre == '' ||
                  form.apellidos == '' ||
                  form.direccion == '' ||
                  form.ciudad == ''
                "
              >
                Continuar
              </v-btn>
              <v-btn
                :to="{ name: 'carts', params: { id: $route.params.id } }"
                color="error"
                large
                dark
                >Volver</v-btn
              >
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-3" style="padding: 0 0 25px">
              <v-layout row wrap justify-center>
                <v-flex sm6>
                  <v-text-field
                    v-model="search"
                    box
                    append-icon="search"
                    label="Buscar"
                    clearable
                    hide-details
                  />
                </v-flex>

                <v-data-table
                  :headers="[
                    { text: 'SKU-ITEM', value: 'SKU' },
                    { text: 'Producto', value: 'producto.nombre' },
                    { text: 'Item', value: 'nombre' },
                    { text: 'stock', value: 'cantidad_stock' },
                    { text: 'Precio', value: 'precio_bruto' },
                    { text: 'Descuento', value: 'descuento' },
                    { text: 'Cantidad' },
                  ]"
                  :items="stockProductos"
                  :search="search"
                  :rows-per-page-items="[8, 25, 35, 50, 100, 150]"
                  class="elevation-3"
                  style="padding: 8px 25px"
                >
                  <tr slot="items" slot-scope="props">
                    <td class="px-3">
                      {{ props.item.SKU }}
                    </td>
                    <td class="px-3">
                      {{ props.item.producto.nombre }}
                    </td>
                    <td class="px-3">
                      {{ props.item.nombre }}
                    </td>
                    <td class="px-3">
                      {{ props.item.cantidad_stock }}
                    </td>
                    <td
                      class="px-3"
                      v-if="props.item.precio_con_descuento && props.item.precio_con_descuento > 0"
                    >
                      ${{ formatMoney(props.item.precio_con_descuento) }}
                    </td>
                    <td class="px-3" v-else>${{ formatMoney(props.item.precio_bruto) }}</td>
                    <td class="px-3">{{ props.item.descuento }}</td>
                    <td class="px-3">
                      <v-text-field
                        v-model="props.item.cantidad"
                        type="number"
                        outline
                        style="height: 60px !important"
                      />
                    </td>
                  </tr>
                </v-data-table>
              </v-layout>
            </v-card>
            <v-layout row wrap justify-center style="padding-bottom: 25px">
              <v-btn large color="primary" @click="siguiente()"> Continuar </v-btn>
              <v-btn large @click="e1 = 1" color="error" dark>Volver</v-btn>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card class="mb-5">
              <v-layout row wrap justify-center>
                <v-flex xs12 md8>
                  <v-toolbar color="cyan" dark>
                    <v-toolbar-title>Carro de Compras</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-data-table
                    :headers="[
                      { text: 'Producto' },
                      { text: 'Item' },
                      { text: 'Cantidad' },
                      { text: 'subtotal' },
                      { text: 'Acciones' },
                    ]"
                    :items="tableData"
                    :rows-per-page-items="[150]"
                    class="elevation-1"
                  >
                    <tr slot="items" slot-scope="props">
                      <td class="px-3">
                        {{ props.item.producto }}
                      </td>
                      <td class="px-3">
                        {{ props.item.nombre }}
                      </td>
                      <td class="px-3">
                        {{ props.item.cantidad }}
                      </td>
                      <td class="px-3">${{ formatMoney(props.item.precio) }}</td>
                      <td class="px-3">
                        <v-btn
                          class="ma-0"
                          small
                          icon
                          flat
                          color="error"
                          @click="removeTodo(props.item.item_id)"
                        >
                          <v-icon small> delete </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </v-data-table>
                </v-flex>
                <v-flex xs12 md3>
                  <v-card>
                    <v-toolbar color="cyan" dark>
                      <v-toolbar-title>Datos para despacho</v-toolbar-title>

                      <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-list two-line dense>
                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon>person</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                          <v-list-tile-sub-title>Nombre</v-list-tile-sub-title>
                          <v-list-tile-title>{{ form.nombre }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon>person</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                          <v-list-tile-sub-title>Apellidos</v-list-tile-sub-title>
                          <v-list-tile-title>{{ form.apellidos }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon>mail</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                          <v-list-tile-sub-title>E-mail</v-list-tile-sub-title>
                          <v-list-tile-title>{{ email }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon>phone</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                          <v-list-tile-sub-title>Teléfono</v-list-tile-sub-title>
                          <v-list-tile-title>{{ form.telefono }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon>location_on</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                          <v-list-tile-sub-title>Dirección</v-list-tile-sub-title>
                          <v-list-tile-title>{{ form.direccion }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon>location_on</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                          <v-list-tile-sub-title>Villa / Población / Otro</v-list-tile-sub-title>
                          <v-list-tile-title>{{ form.ubicacion }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile>
                        <v-list-tile-avatar>
                          <v-icon>map</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                          <v-list-tile-sub-title>Ciudad</v-list-tile-sub-title>
                          <v-list-tile-title>{{ ciudad }}</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-center style="padding-bottom: 25px">
                <v-btn color="primary" @click="submitCotizacion" v-if="tableData.length > 0" large>
                  Continuar
                </v-btn>
                <v-btn @click="e1 = 2" color="error" dark large>Volver</v-btn>
              </v-layout>
            </v-card>
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="headline grey lighten-2" secundary-title>
                  {{ errorMessage }}
                </v-card-title>
                <v-card-text>
                  <v-flex xs12>
                    <v-data-table
                      :headers="[{ text: 'Producto' }, { text: 'Item' }]"
                      :items="errorItems"
                      hide-actions
                      class="elevation-1"
                    >
                      <tr slot="items" slot-scope="props">
                        <td class="px-3">
                          {{ props.item.producto.nombre }}
                        </td>
                        <td class="px-3">
                          {{ props.item.nombre }}
                        </td>
                      </tr>
                    </v-data-table>
                  </v-flex>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" flat @click="dialog = false"> Cerrar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-layout row wrap justify-center>
              <v-flex sm7>
                <v-card style="padding: 0px 0px 12px; border-radius: 12px; margin-bottom: 15px">
                  <v-toolbar color="primary" dark>
                    <v-toolbar-title>Forma de Despacho</v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-list v-if="courier.length > 0" style="padding: 25px">
                    <label for="">Seleccione courier:</label>
                    <br />
                    <el-radio-group v-model="courier_id">
                      <el-radio
                        v-for="(item, index) in courier"
                        :label="index"
                        :key="index"
                        border
                        @change="addCourier(item)"
                      >
                        {{ item.nombre_courier }} - valor: ${{ formatMoney(item.costo) }}
                      </el-radio>
                    </el-radio-group>
                  </v-list>
                </v-card>
                <v-card style="border-radius: 12px">
                  <v-toolbar color="cyan" dark>
                    <v-toolbar-title>Carro de Compras</v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-data-table
                    :headers="[
                      { text: 'Producto' },
                      { text: 'Item' },
                      { text: 'Cantidad' },
                      { text: 'subtotal' },
                    ]"
                    :items="tableData"
                    hide-actions
                    :rows-per-page-items="[10, 25, 35, 50]"
                    class="elevation-1"
                  >
                    <tr slot="items" slot-scope="props">
                      <td class="px-3">
                        {{ props.item.producto }}
                      </td>
                      <td class="px-3">
                        {{ props.item.nombre }}
                      </td>
                      <td class="px-3">
                        {{ props.item.cantidad }}
                      </td>
                      <td class="px-3">${{ formatMoney(props.item.precio) }}</td>
                    </tr>
                  </v-data-table>

                  <v-layout row wrap style="margin-top: 11px">
                    <v-flex md6 sm6 xs12> </v-flex>
                    <v-flex md3 sm3 xs12> Subtotal: </v-flex>
                    <v-flex md3 sm3 xs12>
                      <h3 v-if="e1 === 4">${{ formatMoney(this.getPrecioTotal()) }}</h3>
                    </v-flex>
                    <v-flex md6 sm6 xs12> </v-flex>
                    <v-flex md3 sm3 xs12> Courier : </v-flex>
                    <v-flex md3 sm3 xs12>
                      <h3 v-if="e1 === 4 && courier_item != ''">
                        ${{ formatMoney(courier_item.costo) }}
                      </h3>
                    </v-flex>
                    <v-flex md6 sm6 xs12> </v-flex>
                    <v-flex md3 sm3 xs12> Total: </v-flex>
                    <v-flex md3 sm3 xs12>
                      <h3 v-if="e1 === 4 && courier_item != ''">
                        ${{ formatMoney(this.getPrecioTotal() + courier_item.costo) }}
                      </h3>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex sm3>
                <v-card>
                  <v-toolbar color="cyan" dark>
                    <v-toolbar-title>Datos para despacho</v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>

                  <v-list two-line dense>
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>person</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Nombre</v-list-tile-sub-title>
                        <v-list-tile-title>{{ form.nombre }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>person</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Apellidos</v-list-tile-sub-title>
                        <v-list-tile-title>{{ form.apellidos }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>mail</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>E-mail</v-list-tile-sub-title>
                        <v-list-tile-title>{{ email }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>phone</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Teléfono</v-list-tile-sub-title>
                        <v-list-tile-title>{{ form.telefono }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>location_on</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Dirección</v-list-tile-sub-title>
                        <v-list-tile-title>{{ form.direccion }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>location_on</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Villa / Población / Otro</v-list-tile-sub-title>
                        <v-list-tile-title>{{ form.ubicacion }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>map</v-icon>
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-sub-title>Ciudad</v-list-tile-sub-title>
                        <v-list-tile-title>{{ ciudad }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center style="padding: 25px 0">
              <v-btn color="primary" large @click="submitCreateCart" v-if="courier_item != ''" :loading="btn_loading">
                Registrar pedido
              </v-btn>
              <v-btn @click="e1 = 2" color="error" dark large>Cancelar</v-btn>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-container>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { baseUrlAPI } from "@/config/api";
import axios from "axios";
const HOST = baseUrlAPI;

export default {
  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
  },
  data() {
    return {
      e1: 0,
      formErrors: {},
      name: "",
      search: "",
      email: "",
      dialog: false,
      errorMessage: "",
      errorItems: [],
      form: {
        nombre: "",
        apellidos: "",
        telefono: "",
        celular: "",
        direccion: "",
        ubicacion: "",
        ciudad: "",
        notas: "",
      },
      formCart: {
        cupon: "",
        items: [],
        ciudad_origen_id: 175,
        ciudad_destino_id: "",
      },
      courier_id: "",
      courier_item: "",
      courier: [],
      tableData: [],
      validForm: true,
      processingForm: false,
      rules: {},
      ciudad: "",
      btn_loading: false,
    };
  },
  computed: {
    ...mapState({
      stockProductos: (state) => state.bodegas.stockProductos,
      cities: (state) => state.cities.cities,
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
    }),
  },
  mounted() {
    this.getCities({ paisId: 1 });
    this.getProductosStock();
  },
  methods: {
    ...mapActions({
      getCities: "cities/getCities",
      getCity: "cities/getCity",
      getProductosStock: "bodegas/getProductosStock",
      createCotizacion: "cotizaciones/createCotizacion",
      createCart: "carts/createCart",
    }),
    getPrecioTotal() {
      let sum = 0;
      let shopping = this.form.item_productos;
      for (let i = 0; i < shopping.length; i++) {
        sum = sum + shopping[i].precio;
      }
      return sum;
    },
    async city(id) {
      try {
        const response = await this.getCity({ cityId: id });
        this.ciudad = response.data.data.nombre;
      } catch (error) {
        console.log(error);
      }
    },
    formatMoney(numero) {
      return new Intl.NumberFormat(["ban", "id"]).format(numero);
    },
    async searchForEmail() {
      let email = this.email;
      const res = await axios.get(`${HOST}/clientes/search?email=${email}`);
      this.form.nombre = res.data.data.nombre;
      this.form.apellidos = res.data.data.apellidos;
      this.form.telefono = res.data.data.telefono;
      this.form.direccion = res.data.data.direccion.direccion;
      this.form.ubicacion = res.data.data.direccion.ubicacion;
      this.form.ciudad = res.data.data.direccion.ciudad_id;
    },
    filterItems() {
      if (this.producto === undefined) {
        this.items = [];
        this.cantidad = "";
        return;
      }
      this.getItems({ productoId: this.producto }).then((response) => {
        this.items = response.data.data;
      });
    },

    siguiente() {
      let datos = this.stockProductos.filter((item) => item.cantidad && item.cantidad > 0);
      this.form.item_productos = datos.map(function (obj) {
        let dato = {
          item_id: obj.id,
          cantidad: obj.cantidad,
          producto: obj.producto.nombre,
          nombre: obj.nombre,
          precio: obj.precio_bruto * obj.cantidad,
        };
        if (obj.descuento != null && obj.precio_con_descuento) {
          dato.precio = obj.precio_con_descuento * obj.cantidad;
        }
        return dato;
      });
      this.city(this.form.ciudad);
      this.tableData = this.form.item_productos;
      this.e1 = 3;
    },
    removeTodo(id) {
      this.tableData = this.tableData.filter((item) => item.item_id != id);
      console.log(this.tableData);
    },
    async submitCotizacion() {
      this.formCart.items = [];
      let shopping = this.tableData;
      for (let i = 0; i < shopping.length; i++) {
        this.formCart.items.push({
          item_id: shopping[i].item_id,
          cantidad: shopping[i].cantidad,
        });
      }
      this.formCart.ciudad_destino_id = this.form.ciudad;
      try {
        const response = await this.createCotizacion({ data: this.formCart });
        this.courier = response.data.data;
        this.e1 = 4;
      } catch (error) {
        this.dialog = true;
        this.errorMessage = error.response.data.messages;
        this.errorItems = error.response.data.errors.items;
        console.table(this.errorItems);
      }
    },
    addCourier(item) {
      this.courier_item = item;
    },
    async submitCreateCart() {
      this.btn_loading = true;
      let data = {
        email: this.email,
        nombre: this.form.nombre,
        apellidos: this.form.apellidos,
        telefono: this.form.telefono,
        celular: this.form.celular,
        direccion: this.form.direccion,
        ubicacion: this.form.ubicacion,
        despacho: this.courier_item.costo,
        ciudad_origen_id: this.formCart.ciudad_origen_id,
        ciudad_destino_id: this.formCart.ciudad_destino_id,
        courier_id: this.courier_item.courier_id,
        courier_codigo: this.courier_item.codigo_courier,
        courier_nombre: this.courier_item.nombre_courier,
        courier_descripcion: this.courier_item.nombre_courier,
        items: this.formCart.items,
        notas: this.form.notas,
      };
      const res = await this.createCart({ data: data })
        .then((response) => {
          this.$router.push({ name: "carts" });
        })
        .catch((error) => {
          this.formErrors = error.response.data.errors || {};
        });
      this.btn_loading = false;
    },
  },
};
</script>
<style scoped>
.wrapper {
  width: 700px;
  display: flex;
}
.first {
  width: 150px;
}
.second {
  margin-left: 30px;
}
.el-dialog {
  width: 20rem !important;
  border-radius: 24px;
}
.el-radio-group {
  width: 340px;
  height: auto;
  margin: 0 !important;
}
.el-radio {
  width: 100%;
  margin-left: 0 !important;
  padding: 20px !important;
  height: 55px !important;
}
.el-radio__label {
  margin-left: 0px !important;
}
.v-list--dense .v-list__tile {
  padding-left: 15px;
}

@media screen and (max-width: 420px) {
  .el-radio-group {
    width: 100% !important;
    height: auto;
    margin: 0 !important;
    font-size: 0.5em !important;
  }
  .el-radio {
    padding-left: 25px !important;
    width: 100%;
    margin-left: 0 !important;
    height: 55px !important;
    white-space: normal;
  }
  .el-radio__label {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 360px) {
  .el-radio-group {
    width: 100% !important;
    height: auto;
    margin: 0 !important;
    font-size: 0.5em !important;
  }
  .el-radio {
    padding: 24px 10px !important;
    width: 100%;
    margin-left: 0 !important;
    height: 65px !important;
    white-space: normal;
  }
  .el-radio__label {
    font-size: 0.8em;
  }
}
</style>
